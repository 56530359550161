<script>
import { required, minLength } from "vuelidate/lib/validators";
import Monitor from '@/services/Monitor';
import validationMessages from '@/components/validations'

export default {
  components:{
    validationMessages
  },
  props: {
    apiData: {
      type: Object,
      default: null
    },
  },

  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      id_e: '',
      status: ["New", "Assigned","In Progress", "Pending", "Resolved"],
      comment: '',
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      showLoader:false,
      modalTitle:'',
      selected: null,
      selectedStatus: ""
    };
  },
  validations: {
    comment: {
      required,
      minLength: minLength(2),
    }
  },
  created() {

  },
  methods: {

    async editApiAlerts() {
      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        try {

          await Monitor.updateApiAlerts(this.apiData.id, {
            comment: this.comment,
            status: this.selectedStatus,
            csrf_token: this.csrf_token

          }).then((response) => {
            this.successmsg("Changes has been saved");
            this.closeModal();
          }).catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg("Changes not saved");
          }).finally(() => {
            this.refreshData();
            this.tryingToEdit = false;
          });
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg("Changes not saved");
        }
      }

      this.tryingToEdit = false;
    },
    handleStatusChange() {
      console.log("Selected status:", this.selectedStatus);
    },

    refreshData() {
      this.$emit('onRefresh') //event from parent
    },
    closeModal() {
      this.showModal = false;
    },


    modalShown(){
      this.modalTitle = "Edit API Alert for " + this.apiData.id
      this.comment = this.apiData.comment
      this.selectedStatus = this.apiData.status
    }

  }
};
</script>

<template>
  <b-modal v-model="showModal"  @shown="modalShown()" id="apiAlerts_edit" :title="modalTitle" title-class="font-18" centered>

    <form @submit.prevent="editApiAlerts">

      <b-form-group label="Comment" label-for="formrow-apialerts-input" class="mb-3">
        <b-form-textarea
            v-model.trim="comment"
            type="text"
            id="comment"
            aria-describedby="comment_e-feedback"
            :class="{
                      'is-invalid': submitted && $v.comment.$error,
                    }"
        >
        </b-form-textarea>
        <validationMessages v-if="submitted" :fieldName="'Comment'" :validationName="$v.comment"></validationMessages>
      </b-form-group>
      <div>
        <b-form-select v-model="selectedStatus" :options="status" @change="handleStatusChange"></b-form-select>
        <div class="mt-3">Selected: <strong>{{ selectedStatus }}</strong></div>
      </div>

      <input type="hidden" name="csrf_token" v-model="csrf_token">
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="editApiAlerts" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Change</b-button>
    </template>
  </b-modal>
</template>